/**
 * @ngdoc Directive
 * @name drawCanvas
 * @description Draw canvas directive
 */
app.directive('drawCanvas', function ($timeout, $rootScope) {
  return {
    link: function ($scope, $elms, $attrs) {
      var canvas = $elms[0];
      var finalCanvas = document.querySelector('#finalCanvas');
      var ctx = canvas.getContext('2d');
      var finalCtx = finalCanvas.getContext('2d');
      var appContent = document.querySelector('.app-content');

      var lastPt = null;
      var ppts = [];

      function setContext() {
        ctx.fillStyle="#FFFFFF";
        finalCtx.fillStyle="#FFFFFF";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        finalCtx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.strokeStyle = "#000000";
        ctx.lineWidth = 2;
        ctx.lineCap = 'round';
        ctx.lineJoin = "round";
      }

      setContext();

      // Set touch listeners
      canvas.addEventListener("touchstart", startHandler, false);
      canvas.addEventListener("touchend", drawEndHandler, false);
      canvas.addEventListener("touchcancel", drawEndHandler, false);
      canvas.addEventListener("touchmove", moveHandler, false);

      // Set mouse listeners
      canvas.addEventListener("mousedown", startHandler, false);
      canvas.addEventListener("mouseup", drawEndHandler, false);
      canvas.addEventListener("mousemove", moveHandler, false);

      function getOffset (obj) {
        return {
          left: obj.offsetLeft,
          top: obj.offsetTop
        };
      }

      function getOffsetXY(e) {
        var x, y;
        var target = e.target || e.srcElement;
        
        if (window.cordova && e.changedTouches instanceof TouchList) {
          e = e.changedTouches[0];

          x = e.clientX - target.offsetLeft - appContent.offsetLeft - getOffset(canvas.parentNode).left;
          y = e.clientY - target.offsetTop - appContent.offsetTop - getOffset(canvas.parentNode).top;

        } else {
          x = e.offsetX;
          y = e.offsetY;
        }

        return {
          x: (x || 0) * canvas.width / canvas.offsetWidth,
          y: (y || 0) * canvas.height / canvas.offsetHeight
        }
      }


      function startHandler (e) {
        e.preventDefault();
        e.stopPropagation();

        lastPt = getOffsetXY(e);
        draw(lastPt.x, lastPt.y);
      }


      function moveHandler (e) {
        e.preventDefault();
        e.stopPropagation();

        if (!lastPt) return;

        var xy = getOffsetXY(e);
        draw(xy.x, xy.y);
      }


      function drawEndHandler (e) {
        e.preventDefault();
        e.stopPropagation();

        finalCtx.drawImage(canvas, 0, 0);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        lastPt = null;
        ppts = [];
        e.preventDefault();
      }


      function draw (x, y) {
        if (!$rootScope.readyToDraw) return;

        ppts.push({x: x, y: y});

        if (ppts.length < 3) {
          var b = ppts[0];
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.beginPath();
          ctx.arc(b.x, b.y, ctx.lineWidth / 2, 0, Math.PI * 2, !0);
          ctx.fill();
          ctx.stroke();
          return;
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.beginPath();
        ctx.moveTo(ppts[0].x, ppts[0].y);

        for (var i = 1; i < ppts.length - 2; i++) {
          var c = (ppts[i].x + ppts[i + 1].x) / 2;
          var d = (ppts[i].y + ppts[i + 1].y) / 2;
          ctx.quadraticCurveTo(ppts[i].x, ppts[i].y, c, d);
        }

        // For the last 2 points
        ctx.quadraticCurveTo(
            ppts[i].x,
            ppts[i].y,
            ppts[i + 1].x,
            ppts[i + 1].y
        );
        ctx.stroke();
        lastPt = {x: x, y: y};
      }

      $scope.$on('$destroy', function () {
        canvas.removeEventListener("touchstart", startHandler, false);
        canvas.removeEventListener("touchend", drawEndHandler, false);
        canvas.removeEventListener("touchcancel", drawEndHandler, false);
        canvas.removeEventListener("touchmove", moveHandler, false);

        canvas.removeEventListener("mousedown", startHandler, false);
        canvas.removeEventListener("mouseup", drawEndHandler, false);
        canvas.removeEventListener("mousemove", moveHandler, false);
      });


    }
  }
});
