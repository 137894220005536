app.directive('sidebar', ["SidebarFactory", "AppFactory", "$window", "$swipe", "$timeout", "AuthService", "$rootScope", "ReducedService", function(SidebarFactory, AppFactory, $window, $swipe, $timeout, AuthService, $rootScope, ReducedService) {
  return {
    template: require('/views/includes/sidebar.html'),
    replace: true,
    link: function($scope, $elm, $attrs) {
      var elm, elmOverlay, elmSidebar, isMove, lastMoveTo, lastX, motionEnd, motionMove, motionStart, moveToX, reset, setStyle, startX, widthSidebar;

      elm = $elm[0];
      elmOverlay = document.getElementById('modal-overlay');
      isMove = false;
      lastX = null;
      moveToX = null;
      lastMoveTo = null;
      startX = null;
      elmSidebar = document.getElementById('sidebar');
      widthSidebar = elmSidebar.offsetWidth;

      motionStart = function(coords, e) {
        if (!AuthService.isSigning() && !ReducedService.login) {
          return;
        }

        if ($rootScope.calendar && $rootScope.calendar.isCalendarActive == true) return;

        startX = null;
        if (!SidebarFactory.isActive()) {
          isMove = (coords.x <= 16) ? true : false;
        } else {
          isMove = true;
        }
        if (isMove) {
          AppFactory.disableSwipe();
          startX = coords.x;
          return moveToX = startX - 300;

        } else {
          // for browser - disables swipe when selecting text in inputs and textarea
          var target = e.target || e.srcElement;
          if (target.value) {
            AppFactory.disableSwipe();
          }
        }
      };

      motionMove = function(coords, e) {
        var deltaX, moveTo, opacity;
        if (!isMove) {
          return;
        }
        elm.classList.add('move');
        elmOverlay.classList.add('move');
        deltaX = coords.x - lastX;
        moveTo = lastX > coords.x ? 'left' : 'right';
        moveToX += deltaX;
        if (moveToX > 0) {
          moveToX = 0;
        }
        if (moveToX < -widthSidebar) {
          moveToX = -widthSidebar;
        }
        opacity = 1 - (Math.abs(moveToX) / widthSidebar);
        lastX = coords.x;
        lastMoveTo = moveTo;
        return setStyle(moveToX, opacity);
      };

      motionEnd = function(coords, e) {
        var endAction;
        endAction = void 0;
        AppFactory.enableSwipe();
        if (!isMove || !lastMoveTo) {
          return reset();
        }
        if (lastMoveTo === 'right') {
          endAction = moveToX >= -widthSidebar * 0.9 ? 'show' : 'hide';
        } else {
          endAction = moveToX <= -widthSidebar * 0.1 ? 'hide' : 'show';
        }
        elmOverlay.style.width = '100%';
        elmOverlay.style.height = '100%';
        if (endAction === 'show') {
          elm.classList.remove('move');
          elm.classList.add('active');
          elm.removeAttribute('style');
          elmOverlay.classList.remove('move');
          elmOverlay.classList.add('active');
          elmOverlay.removeAttribute('style');
        } else {
          elm.classList.remove('move');
          elm.classList.remove('active');
          elm.removeAttribute('style');
          elmOverlay.classList.remove('move');
          elmOverlay.classList.remove('active');
          elmOverlay.removeAttribute('style');
        }

        $timeout(function() {
          if (endAction === 'show') {
            return SidebarFactory.show();
          } else {
            return SidebarFactory.hide();
          }
        });

        return reset();
      };

      $swipe.bind(angular.element($window), {
        start: motionStart,
        move: motionMove,
        end: motionEnd,
        cancel: reset
      });

      setStyle = function(translateX, opacity) {
        //elmSidebar.style.transform = 'translate3d(' + translateX + 'px, 0px, 0px)';
        elmSidebar.style.left = String(translateX) + 'px';
        //elmSidebar.style.webkitTransform = 'translate3d(' + translateX + 'px, 0px, 0px)';
        return elmOverlay.style.opacity = 1 - (Math.abs(moveToX) / widthSidebar);
      };

      $scope.$on('$destroy', function() {
        elm = null;
        elmOverlay = null;
        elmSidebar = null;
        angular.element($window).unbind('mousedown mousemove mouseup touchstart touchmove touchend touchcancel');
      });

      return reset = function() {
        isMove = false;
        moveToX = null;
        lastX = null;
        lastMoveTo = null;
        elm.classList.remove('move');
        return elmOverlay.classList.remove('move');
      };

    }
  };
}]);
