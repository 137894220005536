app.directive('modalWindow', ["ModalWindowService", "$location", "SettingsService", "$timeout", "$window",
  function(ModalWindowService, $location, SettingsService, $timeout, $window) {

  return {
    template: require('/views/directives/modal_window.html'),
    replace: true,

    link: function($scope, element) {
      var elem = element[0];

      function setStyle() {
        var styles = SettingsService.getModalPopupStyles();
        var elmDialogContainer = document.querySelector('.modal-window-content');
        elmDialogContainer.style.width = styles.windowWidth + 'px';
        elmDialogContainer.style.left = styles.marginLeft + 'px';
      }

      $scope.ModalWindow = ModalWindowService;

      $scope.handlerItem = function(item) {
        ModalWindowService.hide();
        if (item.handler) {
          item.handler.apply(this, item.handlerArguments);
        }
        if (item.url) {
          return $location.url(item.url);
        }
      };

      elem.addEventListener('DOMSubtreeModified', setStyle);
      $window.addEventListener('resize', setStyle, false);

      $scope.$on('$destroy', function () {
        elem.removeEventListener('DOMSubtreeModified', setStyle);
        $window.removeEventListener('resize', setStyle, false);
      });

      return $timeout(function() {
        return $scope.$apply(function() {
          return setStyle();
        });
      });

    }
  };
}]);
