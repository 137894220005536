app.directive('imageonerror', function() {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.bind('error', function() {
        scope.$apply(attrs.imageonerror);
      });
    }
  };
});
