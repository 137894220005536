'use strict';

app.directive('signatureField', function($rootScope, Notification) {
  class SignatureField {
    constructor($scope, $element, $attr, $rootScope, Notification) {
      this.$scope = $scope;
      this.$element = $element;
      this.$popup = $element[0].querySelector('.sgn-popup-wrap');
      this.$popup.parentNode.removeChild(this.$popup);
      this.$canvas = this.$popup.querySelector('#finalCanvas');
      this.drawCanvas = this.$popup.querySelector('#drawCanvas');
      this.$comment = this.$popup.querySelector('.sgn-comment');

      this.$rootScope = $rootScope;
      this.Notification = Notification;
      this.routListener = null;

      this.$scope.btnStyle = this._getButtonStyle().toString();
      this.$scope.showPopup = this._showPopup.bind(this);
      this.$scope.hidePopup = this._hidePopup.bind(this);
      this.$scope.clear = this._clear.bind(this);
      this.$scope.setSignature = this._setSignature.bind(this);

      this.$popapRester = angular.element(document).injector().get('PopupRegisterService');
      this.popapResterItem = new this.$popapRester.PopupRegisterItem({
        close: this.$scope.hidePopup,
        skipDefaultAction: true
      });

      $scope.$on('$destroy', () => {
        this.$element = null;
        this.$popup = null;
        this.$canvas = null;
        this.$comment = null;
        this.Notification = null;
        this.routListener = null;
        this.$popapRester = null;
        this.popapResterItem = null;
      });
    }

    setRootScope($rootScope) {
      this.$rootScope = $rootScope;
    }

    _clear () {
      this._clearCanvas();
    }

    _clearCanvas () {
      var ctx = this.$canvas.getContext('2d');
      ctx.clearRect(0, 0, this.$canvas.width, this.$canvas.height);
      ctx.fillStyle="#FFFFFF";
      ctx.fillRect(0, 0, this.$canvas.width, this.$canvas.height);

      var drawCanvas = this.drawCanvas.getContext('2d');
      drawCanvas.clearRect(0, 0, this.drawCanvas.width, this.drawCanvas.height);
      drawCanvas.fillStyle="#FFFFFF";
      drawCanvas.fillRect(0, 0, this.drawCanvas.width, this.drawCanvas.height);
    }

    _setSignature () {
      if (this.$scope.signer !== undefined && !this.$comment) this.$comment = this.$popup.querySelector('.sgn-comment');

      let data = this.$canvas.toDataURL();
      let empty = this._getEmptyData();

      if ( data == empty ) {
        this.Notification.alert_p({
          title: 'error',
          desc: 'errors.enterSignature',
          button: 'ok'
        }, {});
      }
      // else if (!this.$comment.value) this.Notification.alert_p({
      //   title: 'error',
      //   desc: 'errors.enterSigner',
      //   button: 'ok'
      // }, {});
      else {
        this.$scope.signature = data;
        if (this.$scope.signer !== undefined) this.$scope.signer = this.$comment?.value || '';
        this._clearCanvas();
        this._hidePopup();
      }
    }

    _showCurrentSign () {
      var self = this;
      let ctx, drawCtx;

      this.setSignatureSize(true);

      ctx = this.$canvas.getContext('2d');
      drawCtx = this.drawCanvas.getContext('2d');

      ctx.clearRect(0, 0, this.$canvas.width, this.$canvas.height);
      ctx.fillStyle="#FFFFFF";
      ctx.fillRect(0, 0, this.$canvas.width, this.$canvas.height);
      drawCtx.clearRect(0, 0, this.drawCanvas.width, this.drawCanvas.height);
      drawCtx.fillStyle="#FFFFFF";
      drawCtx.fillRect(0, 0, this.drawCanvas.width, this.drawCanvas.height);

      if (this.$scope.field && this.$scope.field.value) {
        let img = new Image();

        img.onload = function() {
          return setTimeout(function() {
            return self.$scope.$apply(function() {
              let imgHeight = self.$canvas.height;
              let imgWidth = self.$canvas.height * img.width / img.height;
              ctx.drawImage(img, 0, 0, imgWidth, imgHeight);
              drawCtx.drawImage(img, 0, 0, imgWidth, imgHeight);
              self.$rootScope.readyToDraw = true;
            });
          });
        };

        img.src = this.$scope.field.value;
      } else {
        return setTimeout(function() {
          return self.$rootScope.readyToDraw = true;
        }, 100);
      }
    }

    _getEmptyData () {
      let c = document.createElement('canvas');
      let ctx = c.getContext('2d');
      c.width = this.$canvas.width;
      c.height = this.$canvas.height;
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(0, 0, c.width, c.height);

      return c.toDataURL();
    }

    _showPopup () {
      var self = this;

      if (this.$rootScope.readOnly && this.$scope.field.value) return;

      function doShowPopupLogic () {
        if (self.$scope.field) {
          self.$comment.value = self.$scope.field.comment || '';
        }


        document.body.querySelector('.app-content').appendChild(self.$popup);

        self.$popapRester.attach(self.popapResterItem);
        self._showCurrentSign();

        window.addEventListener('orientationchange', self.setSignatureSize, true);
        window.addEventListener('resize', self.setSignatureSize, true);
      }

      if (!this.$rootScope.readOnly && this.$scope.field && this.$scope.field.lock && this.$scope.field.value == '') {

        this.$scope.applyValidation({cb: (err) => {
          if(err) {
            if (err.notify) {
              err.message = "errors.signValidationError";
              err.notify();
            }
            return;
          } else {
            return this.Notification.alert_p({
              title: 'notice',
              desc: 'dataWillBeLocked',
              button: 'ok'
            }, {}).then(function() {
              return doShowPopupLogic();
            });
          }
        }});

      } else {
        return doShowPopupLogic();
      }
    }

    _hidePopup () {
      var self = this;
      if (this.$popup.parentNode === null) return;
      this.$rootScope.readyToDraw = false;

      this.$popup.parentNode.removeChild(this.$popup);

      this.$popapRester.detach(this.popapResterItem);

      window.removeEventListener('orientationchange', self.setSignatureSize);
      window.removeEventListener('resize', self.setSignatureSize);

      return this.$scope.setImgWidth();
    }

    _getButtonStyle () {
      //var result = angular.copy(this.$scope.style.button);
      var result = {};
      if(this.$scope.item && this.$scope.item.properties.font_size){
        result['font-size'] = this.$scope.item.properties.font_size
      }


      Object.defineProperty(result, 'toString', {
        value: function () {
          var str = '';

          _.each(result, function (val, key) {
            str += key + ': ' + val + ';';
          });

          return str;
        }
      });

      return result;
    };

    setSignatureSize (firstLoad = 150) {
      setTimeout(() => {
        var canvasBox = document.querySelector('.canvas-signature-wrap');
        var canvasBoxWidth;
        var canvasBoxHeight;
        var wh = window.innerHeight;
        var ww = window.innerWidth;
  
        if (ww > wh) {
          if ((ww / 2) + 100 > wh) canvasBoxWidth = (wh - 120) * 2;
          else canvasBoxWidth = ww - 40;
        } else {
          canvasBoxWidth = ww - 40;
          if (canvasBoxWidth > 600) canvasBoxWidth = 600;
        }

        canvasBoxHeight = canvasBoxWidth / 2;

        canvasBox.style.width = `${canvasBoxWidth}px`;
        canvasBox.style.height = `${canvasBoxHeight}px`;
      }, +firstLoad);
    }
  }
    return {
      template: require('/views/directives/signature-field.html'),
      replace : true,
      scope : {
        field: '=',
        item: '=',
        fieldstyle: '=',
        applyValidation: '&',
        saveAndLock: '&',
        signature: "=",
        signer: "=",
        title: '='
      },

      link : function ( $scope, $element, $attr ) {
        function setImgWidth (event) {
          event = event || window.event;

          var wh = window.innerHeight;
          var ww = window.innerWidth;

          var id, field, width;
          id = '#signature-field-' + Math.floor($scope.field && $scope.field.id || Date.now()/10000);

          if (event && event.type === 'orientationchange') {
            field = document.querySelector(id);
            width = field.clientWidth * wh / ww;
          }

          return setTimeout(function() {
            return $scope.$apply(function() {
              if (!width) {
                field = document.querySelector(id);
                width = field && field.clientWidth || null;
              }

              if($scope.field && $scope.field.value != "") {
                $scope.imgWidth = (width / 2) + 'px';
              } else {
                $scope.imgWidth = 0;
              }

              if (deviceIsIOS) $rootScope.$broadcast('fixScrollOnIOS');
            });
          });
        }

        $scope.setImgWidth = setImgWidth;

        var signatureField = new SignatureField($scope, $element, $attr, $rootScope, Notification);

        // window.addEventListener('orientationchange', setImgWidth);
        // $scope.$on('$destroy', () => {
        //   window.removeEventListener('orientationchange', setImgWidth);
        // });

        return setImgWidth();
      }
    }
  });
