app.directive 'swipePagination', ($swipe, AppFactory, $timeout) ->
  scope:
    options: '=swipePagination'
  link: ($scope, $elements, $attrs) ->
    elm = $elements[0]

    pageWidth = window.innerWidth
    startX = null
    endAction = null

    motionStart = (coords) ->
      if AppFactory.isDisableSwipe() then return
      startX = coords.x

    motionMove = (coords) ->
      if AppFactory.isDisableSwipe() then return
      moveTo = if startX > coords.x then 'right' else 'left'
      isMove = $scope.options.isMove(moveTo)

      return unless isMove

      deltaX = coords.x - startX
      deltaXRatio = deltaX / pageWidth

      if deltaXRatio > 0.3 && !isFirstPage()
        endAction = 'prev'
      else if deltaXRatio < -0.3 && !isLastPage()
        endAction = 'next'
      else
        endAction = null

      if (isFirstPage() and moveTo == 'left') or (isLastPage() and moveTo == 'right')
        deltaX = deltaX / 100 * 20

      indexPage = $scope.options.currentPage - 1
      translateX = -(indexPage * pageWidth - deltaX)

      setTranslate(translateX)

    motionEnd = ->
      if AppFactory.isDisableSwipe() then return

      switch endAction
        when 'prev'
          pageNumber = $scope.options.currentPage - 1
        when 'next'
          pageNumber = $scope.options.currentPage + 1
        else
          pageNumber = $scope.options.currentPage

      indexPage = pageNumber - 1
      translateX = -(indexPage * pageWidth)

      setTranslate(translateX, 200)

      if pageNumber != $scope.options.currentPage
        $scope.options.onChangePage(pageNumber)

      startX = null
      endAction = null

    setTranslate = (x, duration = 0) ->
      elm.style.webkitTransform = 'translate(' + x + 'px, 0px)'
      elm.style.webkitTransitionDuration = "#{duration}ms"

    isFirstPage = ->
      return $scope.options.currentPage == 1

    isLastPage = ->
      return $scope.options.currentPage == $scope.options.countPages

    setWidthPages = ->
      options = $scope.options
      pageWidth = window.innerWidth
      elm.style.width = pageWidth * options.countPages + "px"

      indexPage = options.currentPage - 1
      translateX = -(indexPage * pageWidth)

      setTranslate(translateX)

    $swipe.bind angular.element(elm),
      start: motionStart
      move: motionMove
      end: motionEnd
      cancel: motionEnd

    optionsWatcher = $scope.$watch 'options', (options) ->
      setWidthPages()
    , true

    $scope.$on '$destroy', () ->
      optionsWatcher()
      angular.element(elm).unbind('mousedown mousemove mouseup touchstart touchmove touchend touchcancel')
      elm = null

    $scope.$watch '$destroy', ->
      window.addEventListener('resize', setWidthPages)
      window.addEventListener('orientationchange', setWidthPages)

    window.removeEventListener('resize', setWidthPages)
    window.removeEventListener('orientationchange', setWidthPages)