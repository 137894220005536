// Attach to input time elements and switch their formatting to be HH:MM  (Firefox fix)
app.directive('ngModel', function( $filter ) {
    return {
        require: '?ngModel',
        link: function($scope, $elem, $attrs, ngModel) {
            if( !ngModel )
                return;
            if ( $attrs.type !== 'time' && $attrs.type !== 'date')
                return;
            if ($attrs.type === 'time') {
                ngModel.$formatters.unshift(function(value) {
                    return value.replace(/:00\.000$/, '')
                });
            }
            else if ($attrs.type === 'date') {
                ngModel.$parsers.push(function(value) {
                    let year = new Date(value).getFullYear().toString();
                    if (year.length > 4) {
                        year = year.slice(-4);
                        let newDate = new Date(value);
                        newDate.setFullYear(year);
                        return newDate;
                    }
                    return value;
                });
            }
        }
    }   
})
